// variables
@import "~bootstrap/dist/css/bootstrap.css";

@font-face {
  font-family: "helvetica-tn";
  src: url("./fonts/HelveticaNeueLT35Thin.ttf");
  font-display: block;
}

@font-face {
  font-family: "helvetica-rg";
  src: url("./fonts/HelveticaNeueLT45Light.ttf");
  font-display: block;
}

@font-face {
  font-family: "helvetica-md";
  src: url("./fonts/HelveticaNeueLT65Medium.ttf");
  font-display: block;
}

@font-face {
  font-family: "helvetica-bd";
  src: url("./fonts/HelveticaNeueLT75Bold.ttf");
  font-display: block;
}

@font-face {
  font-family: "helvetica-bk";
  src: url("./fonts/HelveticaNeueLT95Black.ttf");
  font-display: block;
}

// @font-face {
//   font-family: "helvetica-bd";
//   src: url("../public/fonts/font.woff");
//   font-display: swap;
// }
// @font-face {
//   font-family: "helvetica-bd";
//   src: url("../public/fonts/font.woff2");
//   font-display: swap;
// }

$white: #ffffff;
$black: #000000;
.Home_container__1EcsU {
  padding: 0;
}

main {
  height: 100vh;
  margin-top: -115px;
  &.main-director {
    overflow: hidden;
  }
  @media (max-width: 768px) {
    margin-top: -85px;
  }
}
.atagremovesty {
  color: rgb(216, 216, 216);
  text-decoration: none;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
}
body {
  margin: 0;
  padding: 0;
  background-color: $black;
  color: $white;
  font-family: "helvetica-rg";
  p {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1400px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1420px;
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.px-7-custom-1 {
  padding-top: 4.5rem;
  padding-right: 20.5rem;
  padding-bottom: 4.5rem;
  padding-left: 15.5rem;
  margin-left: -14rem;
  width: 20%;
  @media screen and (max-width: 720px) {
    padding-top: 4.5rem;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 1rem;
    margin-left: 0;
  }
}
.px-7-custom-2 {
  padding: 4.5rem 1.5rem;
  @media screen and (max-width: 720px) {
    padding: 2.5rem 1.5rem;
  }
}

.director-slider {
  z-index: 19;
  display: contents;
}

// .pr-3 {
//   padding-right: 25px;
// }
.Main-banner {
  position: relative;
  .video-box {
    video {
      width: 100%;
      height: 100%;
      position: absolute;
      object-fit: cover;
    }
    .video-caption {
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 100px;
      @media screen and (max-width: 720px) {
        bottom: 35px;
      }
      .heading {
        font-size: 56px;
        @media screen and (max-width: 720px) {
          font-size: 35px;
        }
      }
      .para {
        font-size: 16px;
      }
    }
  }
  .progress-bar-section {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    height: 60px;
    margin: 0 auto;
    padding: 0 13px;
    @media screen and (max-width: 720px) {
      height: 25px;
    }
    .progress-bar {
      width: 150px;
      height: 1px;
      margin-left: 70px;
      background-color: rgba(255, 255, 255, 0.5);
      position: relative;
      @media screen and (max-width: 720px) {
        width: 100px;
        margin-left: 12px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        transition: width 4s ease-in;
      }
      &.active {
        width: 725px;
        overflow: visible;
        @media screen and (max-width: 720px) {
          width: 400px;
        }
        &:after {
          width: 100%;
          height: 2px;
          background-color: #ffffff;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
        }
      }
    }
  }
}

.preloader {
  height: 100%;
  position: fixed;
  z-index: 999999999;
  background-color: #000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 2s;
}
.pointCur {
  cursor: pointer;
}
.header {
  &.headerblack {
    background-color: rgba(0, 0, 0, 0.4);
  }
  z-index: 370;
  position: relative;
  width: 100%;
  padding: 30px 0;
  .navbar-brand {
    img {
      height: 45px;
      cursor: pointer;
    }
    padding: 0 0 0 14px;
  }
  @media (max-width: 767px) {
    padding: 15px 0;
    /* Your mobile styles here */
    .navbar-toggler {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
      .navbar-toggler-icon {
        font-size: 20px;
      }
    }
    .navbar-collapse {
      flex-basis: 100%;
      flex-grow: 1;
      align-items: center;
      position: absolute;
      top: 0;
      background: #000;
      width: 100%;
      height: 0;
      left: 0;
      z-index: -1;
      padding-top: 4rem;
      box-shadow: inset 0 -0.5em 2em rgb(255 255 255 / 10%),
        0 0 0 2px rgb(16 1 1), 0.3em 0.3em 1em rgb(0 0 0 / 30%);
      padding-left: 5px;
      transition: all 2s ease-in-out;
      &.show {
        height: 100vh;
      }
    }
  }
  .navbar-nav {
    margin-left: auto;
    @media (max-width: 767px) {
      margin-left: auto;
      bottom: 180px;
      position: absolute;
      right: 0;
    }
    .nav-item {
      cursor: pointer;
      &.Active {
        @media (max-width: 767px) {
          text-decoration: line-through;
        }
      }
      .nav-link {
        text-decoration: none;
        color: #d8d8d8;
        &:hover {
          color: $white;
        }
        font-size: 14px;
        text-decoration: none;
        min-width: 16.5rem;
        text-align: center;
        padding-top: 15px;
        @media (max-width: 767px) {
          /* Your mobile styles here */
          min-width: unset;
          padding: 20px 10px;

          &:first-child {
            padding: 0;
            font-size: 32px;
            text-align: right;
            padding-right: 20px;
          }
        }
      }
    }
  }
}
#player{
  pointer-events: none;
}
#myVideo {
  position: relative;
  right: 0;
  bottom: 0;
  width: 100%;
  max-height: 100%;
  z-index: 1;
  display: block;
  pointer-events: none;
  object-fit: cover;
  @media (max-width: 767px) {
    height: 100vh;
  }
}

.main-content-index {
  position: absolute;
  z-index: 999999;
  width: 100%;
  bottom: 30px;
  @media screen and (max-width: 720px) {
    position: relative;
    top: 33px;
    z-index: 29;
  }
  a {
    text-decoration: none;
    color: #fff;
    h1 {
      text-transform: uppercase;
      font-size: 56px;
      line-height: 0.8;
      padding-bottom: 20px;
      font-weight: 600;
      @media screen and (max-width: 720px) {
        font-size: 30px;
        line-height: 1;
        padding-bottom: 5px;
      }
    }
    p {
      padding: 2px;
      padding-bottom: 40px;
      text-transform: capitalize;
      @media screen and (max-width: 720px) {
        padding-bottom: 10px;
      }
    }
  }
  .progress {
    background-color: transparent;
    height: 0.3rem;
    border-radius: 0;
  }
  .strprogressset {
    margin-left: -25px;
    .strprogress {
      transition: all 1s ease-in-out;
      padding-left: 20px;
      margin-left: 16px;
      .meter {
        height: 1px;
        position: relative;
        background: #888888;
        overflow: hidden;
        width: 100%;
        @media (max-width: 767px) {
          display: none;
        }
        transition: all 1s ease-in-out;
        &.meter100 {
          height: 1px;
          @media (max-width: 767px) {
            display: block;
          }
          width: 100%;
          .wid100 {
            width: 100%;
            .progress {
              animation-timing-function: ease;
              background-color: #ffffff;
              animation-duration: 13s;
              animation-name: progressBar;
              animation-fill-mode: both;
            }
          }
        }
      }
    }
  }
  .meter span {
    display: block;
    height: 100%;
  }
  @keyframes progressBar {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
}

.main-content-trans {
  position: absolute;
  z-index: 1;
  width: 100%;
  margin-top: 7.4vh;
  top: 50%;
  left: 50%;
  // margin-top: 114px;
  transform: translate(-50%, -50%);
  .tabs {
    .tab-list {
      text-align: center;
      padding-bottom: 30px;
      @media screen and (max-width: 720px) {
        padding-left: 0;
      }
      .tab-list-item {
        font-weight: 400;
        font-size: 56px;
        text-transform: uppercase;
        display: inline-block;
        list-style: none;
        font-family: helvetica-md;
        margin-bottom: -1px;
        padding: 0.5rem 1.75rem;
        cursor: pointer;
        // font-size: calc(45px + 6 * ((100vw - 320px) / 680));
        color: rgba(121, 121, 121, 0.8);
        @media screen and (max-width: 720px) {
          padding: 0.5rem 0 0.5rem 0.75rem;
          font-size: 35px;
        }

        &.tab-list-active {
          color: #fff;
        }
      }
    }
    .tab-content {
      max-height: 257px;
      min-height: 257px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0;
      }
      .nobul {
        list-style-type: none;
        font-weight: 400;
        line-height: 2.2rem;
        @media screen and (max-width: 720px) {
          text-align: center;
        }
        a {
          color: #dfdfdf;
          text-decoration: none;
          font-size: 21px;
          &:hover {
            color: #ffffff;
          }
        }
      }
    }
  }
}

.pr-50 {
  // padding-right: 50px;
  @media (max-width: 767px) {
    padding-right: 0px;
  }
}
.table > :not(caption) > * > * {
  padding: 0 0;
}
.text-uppcase {
  text-transform: uppercase;
}
.width560 {
  @media (max-width: 767px) {
    width: auto;
  }
}
.directors-details {
    .dtitle {
        padding-top: 50px;
        font-size: 100px;
        @media (max-width: 767px) {
            margin-bottom: 30px;
            /* Your mobile styles here */
            font-size: calc(40px + 6 * ((100vw - 320px) / 680));
        }
        font-weight: 400;
        line-height: 0.9;
        overflow: hidden;
    }
    font-weight: 400;
    line-height: 80px;
    padding-left: 12px;
    overflow: hidden;
  }
  .dtext {
    font-size: 16px;
    padding-bottom: 40px;
    padding-right: 20px;
    line-height: 26px;
    width: 480px;
    @media (max-width: 767px) {
      /* Your mobile styles here */
      width: auto !important;
      padding-bottom: 30px;
      padding-right: 0;
    }
  }
  .mr-c {
    margin: 0 auto;
  }
  h5 {
    font-size: 14px;
    line-height: 14px;
  }
  .worktable {
    color: #ffffff;
    line-height: 5;
    width: 100%;
    @media (max-width: 767px){
      width: 100%;
      margin: 0 !important;
    }
    .headtag {
      line-height: 1;
      padding: 25px 0;
      font-size: 14px !important;
    }
    &.table > :not(caption) > * > * {
      border-bottom-width: 0 !important;
    }
    &.table > tbody > tr {
      &:last-child {
        border-bottom-width: 1px !important;
        border-color: rgba(255, 255, 255, 0.2);
      }
    }
    &.table > tbody > tr > td {
      border-top-width: 1px !important;
      border-color: rgba(255, 255, 255, 0.2);
      font-size: 18px;
      text-transform: uppercase;
      @media screen and (max-width:756px) {
          font-size: 15px;
        }
    }
}
.lineheigth40 {
    line-height: 40px;
    @media screen and (max-width:756px) {
        line-height: 0;
    }
}
.main-content {
  position: relative;
  z-index: 15;
  background-color: $black;
  width: 100%;
  .direction-name-section {
    width: 100%;
    height: 160px;
    overflow: hidden;
    @media (max-width: 767px) {
      height: auto;
    }
    .react-multi-carousel-list {
      overflow: unset;
      .react-multi-carousel-track {
        transform: translate3d(0, 0px, 0px) !important;
        .react-multi-carousel-item {
          display: none;
          &.react-multi-carousel-item--active {
            display: block;
          }
        }
      }
      .react-multiple-carousel__arrow {
        &.react-multiple-carousel__arrow--right {
          left: -13rem;
          right: auto;
          z-index: 38;
          top: -50%;
          &:before {
            content: "\27F6";
            font-size: 16px;
            color: #959290;
            &:hover {
              color: #ffffff;
            }
          }
          @media (max-width: 767px) {
            left: auto;
            right: 0;
            z-index: 38;
            top: -80px;
          }
        }
      }
      .react-multiple-carousel__arrow--left {
        &.react-multiple-carousel__arrow--left {
          left: -15rem;
          right: auto;
          z-index: 38;
          top: -50%;
          &:before {
            content: "\27F5";
            font-size: 16px;
            color: #959290;
            &:hover {
              color: #ffffff;
            }
          }
          @media (max-width: 767px) {
            left: auto;
            right: 10%;
            z-index: 38;
            top: -80px;
          }
        }
      }
    }
  }
  .width705{
    width: 705px;
    @media (max-width: 767px){
      width: 100%;
    }
  }
  .film-card {
  height: 380px;

    .film-card-inner {
      height: 380px;
      position: relative;
      color: #ffffff;
      text-decoration: none;
      @media (max-width: 767px) {
        /* Your mobile styles here */
        height: 209px;
      }
      .film-video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        &.fvideo {
          width: 99%;
          height: 99%;
        }
      }
      .film-card-caption {
        position: fixed;
        z-index: 15;
        top: 0;
        left: 0;
        @media (max-width: 767px) {
          /* Your mobile styles here */
          top: 0;
        }
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        h3 {
          font-weight: bold;
          font-style: 24px;
        }
        p {
          opacity: 0.8;
        }
      }
	  .film-card-caption-two {
        position: absolute;
        z-index: 15;
        top: 0;
        left: 0;
        @media (max-width: 767px) {
          /* Your mobile styles here */
          top: 0;
        }
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        h3 {
          font-weight: bold;
          font-style: 24px;
        }
        p {
          opacity: 0.8;
        }
      }
    }
    &:nth-child(odd) .film-card-inner {
      overflow: hidden;
      padding: 5px;
     @media (max-width: 767px) {
          padding: 1px;
        }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    &:nth-child(even) .film-card-inner {
      height: 100%;
      overflow: hidden;
       padding: 5px;
      @media (max-width: 767px) {
          padding: 1px;
        }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  .reactSlider {
    ul {
      li {
        width: max-content !important;
        text-transform: uppercase;
      }
    }
    .directors-details {
      position: relative;
      display: block;
      z-index: 99;
      overflow: hidden;
    }
    .paginate-slider {
      div {
        padding-left: 90px;
        @media (max-width: 767px) {
          padding-left: 0px;
        }
        transform: translate(-15px, 20px);
        @media (max-width: 767px) {
          transform: translate(-15px, 20px);
        }
      }
    }
    .react-multi-carousel-list {
      height: 34rem;
      @media (max-width: 767px) {
        height: 18rem;
      }
      .image-item {
        // height: 670px;
        width: 670px !important;
        cursor: pointer;
      }
      .react-multi-carousel-dot-list {
        top: 0;
        right: auto;
      }
      .react-multiple-carousel__arrow--right {
        left: 93%;
        top: -20px;
        background: transparent;
        @media (max-width: 767px) {
          left: 90%;
          top: -10px;
        }
        &:before {
          content: "\27F6";
        }
      }
      .react-multiple-carousel__arrow--left {
        left: 90.4%;
        top: -20px;
        background: transparent;
        @media (max-width: 767px) {
          left: 80.4%;
          top: -10px;
        }
        &:before {
          content: "\27F5";
        }
      }
    }
  }
  .bg-col-sty {
    background-color: #000000;
    z-index: 19;
  }
}
.videoimgdir {
  #video_box {
    float: left;
    position: relative;
    width: 100%;
    z-index: 1;
    min-height: 100%;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      min-height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 300000;
    }
  }
}
.videoimgdir2 {
  .videobg {
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: -115px;
  }
  #video_overlays2 {
    width: 100%;
    min-height: auto;
    background-color: #00000069;
    z-index: 300000;
  }
  .directors-details {
    position: relative;
    display: block;
    z-index: 99;
    overflow: hidden;
  }
}
.videoimg {
  #video_box {
    float: left;
    position: relative;
    width: 100%;
    z-index: 1;
    max-height: 100%;
  }
}

.footer {
  background-color: $black;
  position: relative;
  z-index: 1;
  padding-top: 2rem;
  .footer-content {
    border-top: 1px solid #fff;
    display: inline-flex;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    .navbar-nav {
      flex-direction: unset;
      float: right;
      .nav-link {
        text-decoration: none;
        color: #d8d8d8;
        font-size: 14px;
        text-decoration: none;
        min-width: 16.5rem;
        text-align: center;
        padding-top: 15px;
        @media (max-width: 767px) {
            min-width: unset;
        }
      }

      @media (max-width: 767px) {
        float: none;
        flex-wrap: wrap;
        li {
          width: 50%;
          a {
            text-align: center;
            min-width: 7.5rem;
          }
        }
      }
    }
    @media (max-width: 767px) {
      /* Your mobile styles here */
      padding-bottom: 0;
      display: block;
      .navbar-brand {
        text-align: center;
        display: block;
      }
    }
  }
  @media (max-width: 767px) {
    .header {
      padding-bottom: 0;
    }
  }
}

.page-heading {
  padding: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 6rem;
    @media (max-width: 767px) {
      font-size: 4rem;
    }
    font-weight: 600;
  }
}

.contact {
  .title-contact {
    height: 70vh;
    position: relative;
    @media (max-width: 767px) {
      height: auto;
      position: absolute;
      #myVideo {
        position: fixed;
      }
    }
    .title-contact-h1 {
      z-index: 15;
      width: 100%;
      padding-top: 0;
      position: absolute;
      bottom: 15px;
      left: 0;
      right: 0;
      @media (max-width: 767px) {
        top: 100px;
      }
      h1 {
        font-size: 100px;
        @media (max-width: 767px) {
          font-size: 4rem;
        }
        font-weight: 600;
        text-transform: uppercase;
        font-family: helvetica-tn;
      }
    }
  }
  .contact-content {
    padding-top: 15px;
    position: relative;
    z-index: 15;
    background-color: #000000;
    width: 100%;
    @media (max-width: 767px) {
      background-color: transparent;
      padding-top: 80px;
    }
    .left-card {
      * {
        color: #fff !important;
        margin-left: 12px;
      }
      card {
        padding: 25px 15px;
        display: block;
        p {
          margin-bottom: 5px;
          font-size: 14px;
          text-transform: capitalize;
        }
        h2 {
          text-transform: uppercase;
          font-size: 2.2rem;
          font-weight: 500;
          @media (max-width: 767px) {
            font-size: 1.9rem;
          }
        }
        h5 {
          font-size: 1.45rem;
        }
        h6 {
          font-size: 0.8rem;
        }
      }
    }
    .right-card {
      * {
        color: #fff !important;
        margin-left: 12px;
      }
      card {
        padding: 25px 15px;
        display: block;
        p {
          margin-bottom: 5px;
          text-transform: capitalize;
        }
        h3 {
          text-transform: uppercase;
          font-size: 1.6rem;
          font-weight: 500;
          @media (max-width: 767px) {
            font-size: 1.2rem;
          }
        }
        h5 {
          font-size: 1.2rem;
        }
      }
    }
  }
}

.about-content {
  padding-top: 63px;
  padding-bottom: 50px;
  @media (max-width: 767px) {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .about-top {
    p {
      font-size: 2rem;
      font-weight: 600;
      padding: 0 14px 0 14px;
      @media (max-width: 767px) {
        font-size: 1.8rem;
        font-weight: 500;
        padding-right: 0;
      }
      span {
        font-size: 14px;
        float: left;
        line-height: 4;
        font-weight: 200;
        padding: 0 20rem 0 0;
        @media (max-width: 767px) {
          padding: 0 70px 0 0px;
        }
        margin: 0;
        text-transform: capitalize;
      }
    }
  }
  .about-center {
    p {
      padding-top: 65px;
      padding-left: 14px;
      font-size: 16px;
      padding-right: 135px;
      line-height: 2;
      @media (max-width: 767px) {
        padding-top: 25px;
        padding-right: 0px;
      }
      &.psize {
        font-size: 14px;
      }
    }
    img {
      padding-top: 120px;
    }
    a {
      text-decoration: none;
      color: rgb(204, 204, 204);
      &:hover {
        color: #fff;
      }
      h4 {
        text-transform: uppercase;
        padding-left: 14px;
      }
    }
  }
}

.vd-card {
  height: 100%;
  overflow: hidden;
  .vd-card-inner {
    .vid-space {
      height: 473px;
      overflow: hidden;
      width: 60%;
      margin: 0 auto;
      position: relative;
      @media (max-width: 767px) {
        height: auto;
        width: 100%;
      }
      overflow: hidden;
      video {
        width: 100%;
      }
    }
    .vd-card-caption-2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        height: 60px;
        display: block;
        margin: 0 auto;
        display: none;
        &.showbtn {
          display: block !important;
        }
        @media (max-width: 767px) {
          height: 30px;
        }
      }
    }
    .vd-card-caption {
      transform: translate(0px, -93px);
      @media (max-width: 767px) {
        transform: none;
      }
      .caption-inner {
        p {
          font-size: 14px;
          margin: 0;
        }
        h1 {
          font-size: 6.3rem;
          @media (max-width: 767px) {
            font-size: 2.3rem;
            margin-left: 0;
          }
          margin-left: -6px;
          padding: 0 3px;
          margin-bottom: 6px;
        }
      }
      p {
        font-size: 14px;
      }
    }
  }
}
body.modal-open {
  overflow: hidden;
}
.ModalCustom{
    &.modal-dialog {
        max-width: 100vw;
        height: 80vh;
        margin-top: 5vh;

        @media (max-width: 767px) {
          bottom: -125px;
        }
        .modal-content {
          background-color: #000 !important;
          border: none !important;
          .modal-header {
            padding: 0 !important;
          }
        }
        .plyr__controls__item:first-child {
          position: absolute;
          left: 10px;
          z-index: 99999999;
          top: 60px;
        }

        .plyr {
            .plyr__control.plyr__tab-focus, .plyr__control:hover, .plyr__control[aria-expanded=true] {
               background: #000;
            }
            .plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
                padding-bottom: 50.25%;
            }
            .plyr__controls{
                left: 5.0vw !important;
                background: transparent !important;
                display: block !important;
                right: 20px;
                @media (max-width:767px){
                    bottom:-125px;
                }
                .plyr__controls__item:first-child {
                    position: absolute;
                    left: 10px;
                    z-index: 99999999;
                    top: 45px;
                }
                .plyr__controls__item.plyr__time {
                    position: absolute;
                    z-index: 999999;
                    left: 50px;
                    top: 52px;
                }
                .plyr__controls__item {
                    margin: 25px 0 0 auto !important;
                    @media screen and (max-width:767px) {
                        margin: 40px 0 0 auto !important;
                    }
                }
                .plyr__progress {
                    overflow: hidden;
                    input[type=range], .plyr__progress__buffer {
                        margin-left: calc(-74px * -.5);
                        margin-left: calc(var(--plyr-range-thumb-height,5px) * -.5);
                        margin-right: calc(13px * -.5);
                        margin-right: calc(var(--plyr-range-thumb-height,5px) * -.5);
                        width: calc(100% + 13px);
                        width: calc(100% + var(--plyr-range-thumb-height,-74px));
                        height: 2px;
                        border: 0;
                        @media screen and (max-width:767px) {
                            margin-right: 0;
                            width: 100%;
                        }
                    }
                    input[type=range] {
                        color:#fff !important;
                        height: 2px;
                    }

                }
            }
            &.plyr--video .plyr__control--overlaid {
                background: transparent;
                top: 45%;
                border: 5px solid #fff;
                padding: calc(var(--plyr-control-spacing,10px) * 1.0);
            }
        }
        .plyr__controls__item {
          margin: 25px 0 0 auto !important;
          @media screen and (max-width: 767px) {
            margin: 40px 0 0 auto !important;
          }
        }
        .plyr__progress {
          overflow: hidden;
          input[type="range"],
          .plyr__progress__buffer {
            margin-left: calc(-74px * -0.5);
            margin-left: calc(var(--plyr-range-thumb-height, 5px) * -0.5);
            margin-right: calc(13px * -0.5);
            margin-right: calc(var(--plyr-range-thumb-height, 5px) * -0.5);
            width: calc(100% + 13px);
            width: calc(100% + var(--plyr-range-thumb-height, -74px));
            height: 2px;
            border: 0;
            @media screen and (max-width: 767px) {
              margin-right: 0;
              width: 100%;
            }
          }
          input[type="range"] {
            color: #fff !important;
            height: 2px;
          }
        }
      }
      &.plyr--video .plyr__control--overlaid {
        background: transparent;
        top: 45%;
        border: 5px solid #fff;
        padding: calc(var(--plyr-control-spacing, 10px) * 1);
      }
    
    .videoModel {
      padding: 0;
      margin: 0;
      .st0 {
        text-transform: uppercase;
        font-size: 21px;
        padding-left: 5.8vw;
      }
      .st1 {
        text-transform: capitalize;
        font-size: 14px;
        padding-left: 5.8vw;
      }
      & > button {
        position: relative;
        margin-left: auto;
        display: block;
        top: -50px;
        padding: 0px;
        margin-right: 6.3vw;
        font-size: 13px;
      }
      > div {
        width: auto !important;
        min-height: 70vh !important;
        top: 0;
        position: relative;
        @media (max-width: 767px) {
          min-height: 25vh !important;
          height: 24px !important;
          overflow: initial;
        }
      }
    }
  }

.ModalCustom2 {
  &.modal-dialog {
    max-width: 100vw;
    height: 80vh;
    margin-top: 5vh;

    @media (max-width: 767px) {
      max-width: 100vw;
      margin-top: 0;
    }
    // margin: 5.75rem auto;
    .modal-content {
      background-color: #000 !important;
      border: none !important;
      .modal-header {
        padding: 0 !important;
      }
    }

    .plyr {
      .plyr__control.plyr__tab-focus,
      .plyr__control:hover,
      .plyr__control[aria-expanded="true"] {
        background: #000;
      }
      .plyr__video-embed,
      .plyr__video-wrapper--fixed-ratio {
        padding-bottom: 50.25%;
      }
      .plyr__controls {
        left: 5vw !important;
        background: transparent !important;
        display: block !important;
        bottom: 55px;
        right: 20px;
        @media screen and (max-width: 720px) {
          bottom: 0;
          right: 0;
          .plyr__volume {
            float: right;
            top: 5px;
          }
        }
        .plyr__controls__item:first-child {
          position: absolute;
          left: 10px;
          z-index: 99999999;
          top: 60px;
          @media screen and (max-width: 720px) {
            top: 30px;
          }
        }
        .plyr__controls__item.plyr__time {
          position: absolute;
          z-index: 999999;
          left: 50px;
          top: 65px;
          @media screen and (max-width: 720px) {
            top: 35px;
          }
        }

        .plyr {
            .plyr__control.plyr__tab-focus, .plyr__control:hover, .plyr__control[aria-expanded=true] {
               background: #000;
            }
            .plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
                padding-bottom: 50.25%;
            }
            .plyr__controls{
                left: 5.0vw !important;
                background: transparent !important;
                display: block !important;
                right: 20px;
                .plyr__controls__item:first-child {
                    position: absolute;
                    left: 10px;
                    z-index: 99999999;
                    top: 45px;
                }
                .plyr__controls__item.plyr__time {
                    position: absolute;
                    z-index: 999999;
                    left: 50px;
                    top: 52px;
                }
                .plyr__controls__item {
                    margin: 25px 0 0 auto !important;
                }
                .plyr__progress {
                    overflow: hidden;
                    input[type=range], .plyr__progress__buffer {
                        margin-left: calc(-74px * -.5);
                        margin-left: calc(var(--plyr-range-thumb-height,5px) * -.5);
                        margin-right: calc(13px * -.5);
                        margin-right: calc(var(--plyr-range-thumb-height,5px) * -.5);
                        width: calc(100% + 13px);
                        width: calc(100% + var(--plyr-range-thumb-height,-74px));
                        height: 2px;
                        border: 0;
                    }
                    input[type=range] {
                        color:#fff !important;
                        height: 2px;
                        right: 3rem;
                    }

                }
            }
            &.plyr--video .plyr__control--overlaid {
                background: transparent;
                top: 45%;
                border: 5px solid #fff;
                padding: calc(var(--plyr-control-spacing,10px) * 1.0);
            }
        }
        .plyr__progress {
          overflow: hidden;
          input[type="range"],
          .plyr__progress__buffer {
            margin-left: calc(-74px * -0.5);
            margin-left: calc(var(--plyr-range-thumb-height, 5px) * -0.5);
            margin-right: calc(13px * -0.5);
            margin-right: calc(var(--plyr-range-thumb-height, 5px) * -0.5);
            width: calc(100% + 13px);
            width: calc(100% + var(--plyr-range-thumb-height, -74px));
            height: 2px;
            border: 0;
            @media screen and (max-width: 720px) {
              width: 100%;
              right: 3rem;
            }
          }
          input[type="range"] {
            color: #fff !important;
            height: 2px;
          }
        }
      }
      &.plyr--video {
        overflow: visible;
        .plyr__control--overlaid {
          background: transparent;
          top: 45%;
          border: 5px solid #fff;
          padding: calc(var(--plyr-control-spacing, 10px) * 1);
        }
      }
    }
    .videoModel {
      padding: 0;
      margin: 0;
      .st0 {
        text-transform: uppercase;
        font-size: 21px;
        padding-left: 5.8vw;
      }
      .st1 {
        text-transform: capitalize;
        font-size: 14px;
        padding-left: 5.8vw;
      }
      & > button {
        position: relative;
        margin-left: auto;
        display: block;
        top: -50px;
        padding: 0px;
        margin-right: 6.3vw;
        font-size: 13px;
      }
      > div {
        width: auto !important;
        min-height: 70vh !important;
        top: 0;
        position: relative;
        @media (max-width: 767px) {
          min-height: 25vh !important;
          height: 195px !important;
        }
      }
    }
  }
}

.show-pointer {
  cursor: pointer;
}
.paddingl60{
  @media (max-width: 1600px) {
    padding-left: 4.1rem;
    margin-left: 40em;
  }
  @media (max-width: 1440px) {
    padding-left: 4.1rem;
    margin-left: 40em;
  }
  @media (max-width: 1366px) {
    padding-left: 2.5rem;
  }
  @media (max-width: 1200px) {
    padding-left: 1.5rem;
  }
    // padding-left: 2.5rem;
    margin-left: 40em;
}
.pl-70 {
  // padding-left: 39px;
  @media (max-width: 767px) {
    padding-right: 0px;
    padding-left: 0;
  }
}
.width500 {
  width: 460px;
  @media (max-width: 767px) {
    padding-right: 0px;
    width: 0;
    display: none;
  }
}
.modal {
  background-color: #000 !important;
  @media (max-width: 767px){
  padding-top: 11rem;
  overflow: hidden;
  }
}

@media screen and (max-width: 760px) {
  .footer,
  .mob-responce-remove {
    display: none !important;
  }
  .dtitle{
    margin-left: 0;
  }
  .directors-details {
     padding-left: 0;
}
  .pr-3 {
    padding-right: 12px;
    padding-left: 12px;
  }
  .toggle-navbar{
    height: 100vh;
    overflow: hidden;
  }
  .film-card {
  height: 242px !important;
}
  .director-gif{
    width: 393px;
    height:212.66px;
  }
  .film-card-inner{
    height: 242px !important;
    padding-top: 1px;
  }
  .film-video{
    width: 428px !important;
    height: 242px !important;
  }
  #slider-heading{
   margin-left: 1rem !important;
  }
  .paddingl60 {
    padding-left: 12px !important;
    padding-right: 12px !important;
    margin-left: 0 !important;
  }
  ::-webkit-scrollbar {
    display: none !important;
  }
  body {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .alingRight{
    text-align: right;
  }
}
#director-details-par{
   text-transform: none !important;
}
video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;
}
#slider-heading{
  padding: 0;
  margin-left: 4rem;
}
#slider-para{
  margin-left: 5px;
}
.film-section{
  padding-left: 5px;
  padding-right: 5px;
  @media (max-width: 767px) {
      padding: 0px;
  }
}

.main-content .width705 {
  @media (max-width: 1600px) {
	width: 524px !important;
  }
  @media (max-width: 1440px) {
    width: 471px !important;
  }
  @media (max-width: 1366px) {
    width: 441px !important;
  }
  @media (max-width: 1280px) {
    width: 418px !important;
  }
  @media (max-width: 1250px) {
    width: 413px !important;
  }
  @media (max-width: 767px) {
    width: 365px !important;
  }
  @media (max-width: 368px) {
    width: 339px !important;
  }
}